import SmoothScroll from 'smooth-scroll';
function headerMenu(){
    "use strict";
    // SCROLL
    let smoothscroll = new SmoothScroll('a[href*="#"]', {
        speed: 1000
    });

    // MENU ON SCROLL
    window.addEventListener("scroll", () => {
        let header = document.querySelector('header'),
            hr = document.querySelector('.header__underline'),
            logo = document.querySelector('.header__logo > img');

        header.classList.toggle('sticky', window.scrollY > 0);

        // if(window.scrollY > 0) {
        //     logo.src = "icons/logo.svg";
        //     logo.classList.toggle('animate__fadeIn');
        // } else {
        //     logo.src = "icons/logo_full.svg";
        // }


        if(window.scrollY < 870 ) {
            hr.style.left = '';
            hr.style.width = '';
        } else if(window.scrollY === 870 || window.scrollY < 1860){
            hr.style.left = '25%';
            hr.style.width = '88px';
        } else if(window.scrollY === 1860 || window.scrollY < 2400){
            hr.style.left = '46%';
            hr.style.width = '78px';
        }else if(window.scrollY === 2400 || window.scrollY < 3200){
            hr.style.left = '65%';
            hr.style.width = '65px';
        } else if(window.scrollY === 3200 || window.scrollY < 3700){
            hr.style.left = '82.5%';
            hr.style.width = '78px';
        }

    });

    let wordsTranslate = document.querySelectorAll('li');

    wordsTranslate.forEach(word => {
        word.addEventListener('mouseenter', () => {
            word.style.transform = 'translateY(-26px)';
        });
        word.addEventListener('mouseleave', () => {
            word.style.transform = 'translateY(0px)';
        });
    });

    // MENU BEHAVIOR ON MOBILES
    let mq = window.matchMedia("(max-width: 767px)");
    if(mq.matches){

        let words = document.querySelectorAll('li');
        words.forEach(word => {
            word.addEventListener('mouseenter', () => {
                word.style.transform = 'none';
            });
        words.forEach(word => {
            word.addEventListener('click', () => {
                burgerMenu.classList.remove('header__burger_active');
                unorderedList.classList.remove('open');
                document.body.style.overflow = 'unset';
            });
        });
        });
    }

    // BURGER MENU ON MOBILE
    let burgerMenu = document.querySelector('.header__burger'),
        unorderedList = document.querySelector('ul');

    burgerMenu.addEventListener('click', () => {
        burgerMenu.classList.toggle('header__burger_active');
        unorderedList.classList.toggle('open');
        if(burgerMenu.classList.contains('header__burger_active')){
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    });
    
}

export default headerMenu;