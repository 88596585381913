function modals() {
    "use strict";
    let modalClose = document.querySelector('.popup__close'),
        modal = document.querySelector('.popup'),
        overlay = document.querySelector('.popup__overlay'),
        scroll = calcScroll();

    function calcScroll() {
        let div = document.createElement('div');

        div.style.width = '50px';
        div.style.height = '50px';
        div.style.overflowY = 'scroll';
        div.style.visibility = 'hidden';

        document.body.appendChild(div);
        let scrollWidth = div.offsetWidth - div.clientWidth;
        div.remove();

        return scrollWidth;
    }
    
    setTimeout(() => {
        document.body.style.overflow = 'hidden';
        modal.style.display = 'block';
        overlay.style.visibility = 'visible';
        document.body.style.marginRight = `${scroll}px`;
    }, 45000);

    modalClose.addEventListener('click', () => {
        modal.style.display = 'none';
        document.body.style.overflow = '';
        document.body.style.marginRight = '0px';
    });

    overlay.addEventListener('click', () => {
        modal.style.display = 'none';
        document.body.style.overflow = '';
        document.body.style.marginRight = '0px';
    });

    const form = document.querySelector('.popup__form'),
          input = form.querySelectorAll('input'),
          textArea = form.querySelector('textarea');

    
    const message = {
        loading: "icons/spinner.svg",
        success: "Thank you! We will contact you very soon.",
        failure: "Something went wrong..."
    };
    const postData = async (url, data) => {
        document.querySelector('.status').innerHTML = `<img src="${message.loading}"></img>`;
        let res = await fetch(url, {
            method: "POST",
            body: data
        });

        return await res.text();
    };

    const clearInputs = () => {
        input.forEach(item => {
            item.value = "";
        });
        textArea.value = "";
    };

   
    form.addEventListener('submit', (event) => {
        event.preventDefault();

        if(textArea.value === '' || input[0].value === '' || input[1].value === ''){
            let message = document.querySelector('.popup__message');
            message.textContent = 'Please fill in all the fields';
            textArea.style.border = '2px solid red';
            input[0].style.border = '2px solid red';
            input[1].style.border = '2px solid red';
            let clearBorders = () => {
                message.textContent = '';
                textArea.style.border = '';
                input[0].style.border = '';
                input[1].style.border = '';
                clearInterval();
            };
            setTimeout(clearBorders, 5000);
            
        } else {

            let statusMessage = document.createElement('div');
            statusMessage.classList.add('status');
            form.appendChild(statusMessage);

            const formData = new FormData(form);

            let clear = () => {
                let message = document.querySelector('.popup__message');
                message.textContent = '';
                textArea.style.border = '';
                input[0].style.border = '';
                input[1].style.border = '';
            };
            clear();

        postData('mailer/smart.php', formData)
            .then(res => {
                statusMessage.textContent = message.success;
            })
            .catch(() => statusMessage.textContent = message.failure)
            .finally(() => {
                clearInputs();
                setTimeout(() => {
                    statusMessage.remove();
                    modal.style.display = 'none';
                    document.body.style.overflow = '';
                }, 5000);
            });
        }
    });
}
export default modals;