require('es6-promise').polyfill();
import 'nodelist-foreach-polyfill';
import headerMenu from './modules/header_menu';
import forms from './modules/forms';
import modals from './modules/modals';
import timer from './modules/timer';
import WOW from 'wow.js';

window.addEventListener("DOMContentLoaded", () => {
    "use strict";
    setTimeout(() => {
        let preloader = document.querySelector('.preloader');
        if(!preloader.classList.contains('done')){
            preloader.classList.add('done');
        }
    }, 1000);
    headerMenu();
    forms();
    modals();
    timer('.pricing__timer', '2020-10-01');
    new WOW().init();
});


