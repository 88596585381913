function forms() {
    "use strict";
    const form = document.querySelector('form'),
          input = document.querySelectorAll('input'),
          textArea = document.querySelector('textarea');

    
    const message = {
        loading: "icons/spinner.svg",
        success: "Thank you! We will contact you very soon.",
        failure: "Something went wrong..."
    };

    const postData = async (url, data) => {
        document.querySelector('.status').innerHTML = `<img src="${message.loading}"></img>`;
        let res = await fetch(url, {
            method: "POST",
            body: data
        });

        return await res.text();
    };

    const clearInputs = () => {
        input.forEach(item => {
            item.value = "";
        });
        textArea.value = "";
    };

   
    form.addEventListener('submit', (event) => {
        event.preventDefault();

        if(textArea.value === '' || input[0].value === '' || input[1].value === ''){
            let message = document.querySelector('.message');
            message.textContent = 'Please fill in all the fields';
            textArea.style.border = '2px solid red';
            input[0].style.border = '2px solid red';
            input[1].style.border = '2px solid red';
            let clearBorders = () => {
                message.textContent = '';
                textArea.style.border = '';
                input[0].style.border = '';
                input[1].style.border = '';
                clearInterval();
            };
            setTimeout(clearBorders, 5000);
            
        } else {

            let statusMessage = document.createElement('div');
            statusMessage.classList.add('status');
            form.appendChild(statusMessage);

            const formData = new FormData(form);

            let clear = () => {
                let message = document.querySelector('.message');
                message.textContent = '';
                textArea.style.border = '';
                input[0].style.border = '';
                input[1].style.border = '';
            };
            clear();

        postData('mailer/smart.php', formData)
            .then(res => {
                statusMessage.textContent = message.success;
            })
            .catch(() => statusMessage.textContent = message.failure)
            .finally(() => {
                clearInputs();
                setTimeout(() => {
                    statusMessage.remove();
                }, 5000);
            });
        }
    });
}
export default forms;